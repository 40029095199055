<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <form class="m-4"  [formGroup]="issueForm" (ngSubmit)="onSubmit()">

              <div class="row">
                  <div class="col-12 ">
                    <h5>Report an Issue</h5>
                    <hr>

                    <mat-form-field class="example-full-width mb-3" appearance="fill">
                      <mat-label>Please Select Type</mat-label>
                      <mat-select [(ngModel)]="issueModel.type" formControlName="type">
                        <mat-option [value]="'General Feedback'">
                          General Feedback
                        </mat-option>
                        <mat-option [value]="'Technical Issue'">
                          Technical Issue
                        </mat-option>
                        <mat-option [value]="'Typos, Wording, Grammar'">
                          Typos, Wording and Grammar Issues
                        </mat-option>
                        <mat-option [value]="'Unable to Proceed'">
                          Unable to Proceed
                        </mat-option>
                        <mat-option [value]="'Unexpected Behavior'">
                          Unexpected Behavior
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="issueForm.get('type').hasError('required')">
                        Issue Type is required
                      </mat-error>
                    </mat-form-field> 

                    <mat-form-field class="example-full-width mb-3" appearance="fill">
                      <mat-label>Subject</mat-label>
                      <input matInput [(ngModel)]="issueModel.subject"  formControlName="subject">
                      <mat-error *ngIf="issueForm.get('subject').hasError('required')">
                        Subject is required
                      </mat-error>
                    </mat-form-field>


                    <mat-form-field class="example-full-width mb-3" appearance="fill">
                      <mat-label>Issue Description</mat-label>
                      <textarea matInput [(ngModel)]="issueModel.description"  formControlName="description"></textarea>
                      <mat-error *ngIf="issueForm.get('description').hasError('required')">
                        Issue description is required
                      </mat-error>
                    </mat-form-field>



                  </div>
                </div>

                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">

                  <!--    <button class="btn-space" *ngIf="!isEditingUser" [disabled]="!userForm.valid  || userRolesArrPrettyVersion.length <= 0 || loading" [class.auth-spinner]="loading" mat-raised-button
                        color="primary">Submit</button> -->

                        <div class="float-end btn-sec">
                          <button class="btn-space" [class.auth-spinner]="submitClicked" [disabled]="submitClicked || !issueModel.subject || !issueModel.description || !issueModel.type" mat-raised-button color="primary">Submit</button> 
                          <button type="button" mat-button (click)="close()">Close</button>
                      </div>
                    </div>
                  </div>

          </form>
  </div>
