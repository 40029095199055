import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AgenciesService } from 'src/app/core/service/agencies/agencies.service';
import { IssuesService } from 'src/app/core/service/issues/issues.service';

import { Agency } from 'src/app/core/models/agencies/agencies.interface';
import { AuthService } from 'src/app/core/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-issues-modal',
  templateUrl: './issues-modal.component.html',
  styleUrls: ['./issues-modal.component.sass']
})
export class IssuesModalComponent implements OnInit {

  issueForm: FormGroup;
  issueModel: any = {
    subject: "",
    description: "",
    type: ""
  }
  submitClicked: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private AgenciesService: AgenciesService, private AuthService: AuthService, private issuesService: IssuesService,  private snackBar: MatSnackBar, public dialogRef: MatDialogRef<IssuesModalComponent>) {

    this.issueForm = this.fb.group({
      subject: ['', [Validators.required]],
      description: ['', [Validators.required]],
      type: ['', [Validators.required]],

      // More values to come. 
    })

   }

  ngOnInit(): void {
  }

  onSubmit() {
    console.log(this.issueModel);
    this.submitClicked = true;
    // getUrl
    const currentUrl: string = window.location.href;

    this.issuesService.addIssue(this.issueModel, this.AuthService.currentUserValue, currentUrl).then((res:any)=>{
      console.log({res})
      this.submitClicked = false;

      if(res.data.message == "success") {
        this.snackBar.open('Issue reported successfully. Thank you.', '', {
          duration: 5000,
          panelClass: ['customSnackbar']
        }); 
        this.dialogRef.close();
      } else {
        this.snackBar.open('Error reporting issue.', '', {
          duration: 5000,
          panelClass: ['customSnackbar']
        }); 
      }
    })
  } 

  close() {
    this.dialogRef.close();
  }
}
