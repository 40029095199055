import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, firstValueFrom, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc,
  updateDoc
} from "@angular/fire/firestore";
import { orderBy, query } from "@firebase/firestore";
import { Survey, SurveyResponseProcessor } from "../../models/surveys/surveys.interface";
import { getFunctions, httpsCallable } from '@angular/fire/functions';

import * as _ from "lodash";


@Injectable({
  providedIn: "root",
})
export class SurveyService {
  selectedSurvey?: Survey;
  surveys$: Observable<Survey[]>;
  surveys: any = []



  constructor(private firestore: Firestore) { 
  }

  public publishReport(userId: string, skillId: string, activityId: string): void {
    console.log('publishReport', activityId)
    const bookRef = doc(this.firestore, `userProfiles/${userId}/skills/${skillId}/activities/${activityId}`);
    updateDoc(bookRef, {reviewStatus: "Published", status: "Completed"});

  }

  public saveSelectedFeedback(id: string, selectedFeedback: string[], notes: any): void {
    console.log('saveSelectedFeedback', id, selectedFeedback, notes)
    const bookRef = doc(this.firestore, `surveyresponses/${id}`);
    updateDoc(bookRef, {selectedFeedback: selectedFeedback, notes: notes});

  }


  public async getSurveyResponseProcessor(surveyResponseId) {
    var sresponse = (await firstValueFrom(this.getSurveyById(surveyResponseId)))
    var surveyResponse = sresponse.raw.survey_data;
    var survey = (await this.getSurvey(sresponse.surveyId)).data;

    return new SurveyResponseProcessor(surveyResponse, survey);

  }

  public async getSurvey(surveyId: string) {
    const functions = getFunctions();

    const getSurvey = httpsCallable(functions, 'getSurvey');
  
    return getSurvey({surveyId}); 
  }

  public getSurveyById(id: string): Observable<Survey> {
    const bookRef = doc(this.firestore, `surveyresponses/${id}`);
    console.log({ surveyRef: bookRef });
    return docData(bookRef, { idField: "id" }) as Observable<Survey>;
  }

  public getSelfObsById(id: string): Observable<any> {
    const bookRef = doc(this.firestore, `surveyresponses/${id}`);
    console.log({ surveyRef: bookRef });
    return docData(bookRef, { idField: "id" }) as Observable<any>;
  }

  public getSurveys(): Observable<Survey[]> {
    return collectionData<Survey>(
      query<Survey>(
        collection(this.firestore, "surveyresponses") as CollectionReference<Survey>//,
        //orderBy("raw.date_submitted")
      ),
      { idField: "id" }
    );
  }

  public processSurveyData(surveyData: any) {

  }

  public getSurveyQuestion(questionId: any, surveyData: any) {
    if(questionId && surveyData) {
       var questionObj = surveyData[questionId];
       if(questionObj) {
        return questionObj.question;
       } else {
        return "question not found."
       }
    } else {
      return "missing input parameters."
    }
  }

  public getSurveyAnswer(questionId: any, surveyData: any) {
    if(typeof questionId == 'number') {
      console.log(true)
    } else {
      console.log(false)
    }
    if(questionId && surveyData) {
       var questionObj = surveyData[questionId];
       if(questionObj) {
        return questionObj.answer;
       } else {
        return "question not found."
       }
    } else {
      return "missing input parameters."
    }
  }

  // Simple textbox answers etc.
  public getSurveyQuestionAndAnswer(questionId: any, surveyData: any) {

    if(questionId && surveyData) {
       var questionObj = surveyData[questionId];
       if(questionObj.question && questionObj.answer) {
        return {
          question: questionObj.question, 
          answer: questionObj.answer
        };
       } else {
        return "question not found."
       }
    } else {
      return "missing input parameters."
    }
  }

  public getQuestionById(questionId: any, surveyData: any) {
    if(questionId && surveyData) {
      var questionObj = surveyData[questionId];
      if(questionObj.question && questionObj.answer) {
       return {
         question: questionObj.question, 
         answer: questionObj.answer
       };
      } else {
       return "question not found."
      }
   } else {
     return "missing input parameters."
   }
  }


  // Get the ranks the user ordered the options. This will return an array of objects with the id, option and rank (even if rank is a blank string).
   public getRankAnswer(id: any, surveyData: any): any {
    const survey = surveyData[id];

    if (!survey) {
        console.log(`Survey with ID ${id} not found`);
    }

    if (_.isString(survey.answer)) {
        return [{ id: parseInt(id), option: survey.answer, rank: '' }];
    } else {
        const answerObject = survey.answer;
        return Object.values(answerObject).map((answer: any) => ({
            id: answer.id,
            option: answer.option,
            rank: answer.rank
        }));
    }
  }

  // returns checkbox answers
  public getCheckboxAnswers(id: any, surveyData: any): any {
    const question = surveyData[id];

    const checkboxAnswers: any[] = [];

    for (const optionId in question.options) {
        if (question.options.hasOwnProperty(optionId)) {
            const option = question.options[optionId];
            checkboxAnswers.push({
                id: option.id,
                option: option.option,
                answer: option.answer
            });
        }
    }

    return checkboxAnswers;
}

// Returns an array of radio answers from a radio grid that share the same parent. 
public getRadioButtonGrid(parentId: number, surveyData: any):any {
  const radioButtonGrid: any = [];

  for (const key in surveyData) {
      if (surveyData.hasOwnProperty(key)) {
          const question = surveyData[key];
          if (question.parent === parentId && question.type === "RADIO") {
              radioButtonGrid.push(question);
          }
      }
  }

  return radioButtonGrid;
}
}
