// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const WESenvironment = {
  firebase: {
    projectId: "playground-145902",
    appId: "1:217901040567:web:a81169ccc1f4ed7f021e32",
    storageBucket: "playground-145902.appspot.com",
    locationId: "us-central",
    apiKey: "AIzaSyCtNyjxoHUPAV03s_Ic9MoHvOlw4EUkgmo",
    authDomain: "playground-145902.firebaseapp.com",
    messagingSenderId: "217901040567",
    measurementId: "G-KR4S9SKNYQ",
  },
  production: false,
  apiUrl: "http://localhost:4200",
};

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const environment = {
  firebase: {
    apiKey: "AIzaSyD05MrKu2zgYS5vjoeyw212VNGYIPGDxd0",
    authDomain: "praxus-app.firebaseapp.com",
    projectId: "praxus-app",
    storageBucket: "praxus-app.appspot.com",
    messagingSenderId: "223997809639",
    appId: "1:223997809639:web:8e92d7112396e597c0e342",
    measurementId: "G-T3JQDWX91T",
  },
  production: false,
  apiUrl: "http://localhost:4200",
  site: undefined
};
/*
export const environment = {
  firebase: {
  apiKey: "AIzaSyCi8f3nNXjDhRCB2zWubZbhCx2JQIbsJCg",
  authDomain: "praxus-dev-mc.firebaseapp.com",
  projectId: "praxus-dev-mc",
  storageBucket: "praxus-dev-mc.appspot.com",
  messagingSenderId: "797166746313",
  appId: "1:797166746313:web:8dfb7e1a4f9d0eaa6bab18"
  },
  production: false,
  apiUrl: "http://localhost:4200",
};
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
