<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Activity</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/admin/dashboard/main">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item active">Recording</li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Your content goes here  -->
    <div class="row clearfix">
      <div class="col-lg-4 col-md-6">
        <div class="card">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Activity">
              <div class="p-4">
                <ul class="list-group list-group-unbordered">
                  <li class="list-group-item">
                    <b>Type </b>
                    <div class="profile-desc-item float-end">Recording</div>
                  </li>
                  <li class="list-group-item">
                    <b>Skill </b>
                    <div class="profile-desc-item float-end">Role Clarification</div>
                  </li>
                  <li class="list-group-item">
                    <b>Date Assigned</b>
                    <div class="profile-desc-item float-end">01/10/2022</div>
                  </li>
                  <li class="list-group-item">
                    <b>Date Started</b>
                    <div class="profile-desc-item float-end">01/12/2022</div>
                  </li>
                  <li class="list-group-item">
                    <b>Date Completed</b>
                    <div class="profile-desc-item float-end">01/13/2022</div>
                  </li>
                  <li class="list-group-item">
                    <b>Status</b>
                    <div class="profile-desc-item float-end">Pending Coach Review</div>
                  </li>
                </ul>
              </div>
            </mat-tab>
            <mat-tab label="Learner">
              <div class="m-b-20">
                <div class="contact-grid">
                  <div class="profile-header bg-dark">
                    <div class="user-name">Jane Doe</div>
                    <div class="name-center">Probation Officer</div>
                  </div>
                  <img src="assets/images/user/usrbig3.jpg" class="user-img" alt="">
                  <div class="p-4">
                    <ul class="list-group list-group-unbordered">
                      <li class="list-group-item">
                        <b>Agency </b>
                        <div class="profile-desc-item float-end">Main Street Justice</div>
                      </li>
                      <li class="list-group-item">
                        <b>Supervisor </b>
                        <div class="profile-desc-item float-end">Mark Smith</div>
                      </li>
                      <li class="list-group-item">
                        <b>Coach </b>
                        <div class="profile-desc-item float-end">Brian Colgan</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Case Info">
              <div class="p-4">
                <ul class="list-group list-group-unbordered">
                  <li class="list-group-item">
                    <b>Name </b>
                    <div class="profile-desc-item float-end">Joe Johnson</div>
                  </li>
                  <li class="list-group-item">
                    <b>Case # </b>
                    <div class="profile-desc-item float-end">CS20220102-A</div>
                  </li>
                  <li class="list-group-item">
                    <b>Context </b>
                    <div class="profile-desc-item float-end">In Office</div>
                  </li>
                  <li class="list-group-item">
                    <b>Notes </b>
                    <div class="profile-desc-item float-end">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Maecenas dapibus enim vitae vehicula dapibus. Vestibulum pellentesque purus justo, sed blandit
                      sapien feugiat nec. Mauris tristique vitae enim quis efficitur.</div>
                  </li>
                </ul>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div class="card">
          <mat-tab-group mat-align-tabs="start" [(selectedIndex)]="audioNoteTabGroupIndex">
            <mat-tab label="Audio">
              <div class="p-4">
                <audio #audio1 id="audio1" class="ump-media" preload="auto" data-able-player playsinline
                data-transcript-div="audiot" data-skin="2020" data-transcript-title="Transcript">
                  <source type="audio/wav" src="/assets/ableplayer/VID ProbSolvJoel+VAssembly-Mobile-mono.wav" />
                  <track kind="captions" src="/assets/ableplayer/WEBVTT" srclang="en" label="English" />
                </audio>
                <!-- audio  #audio1 controls>
                    <source type="audio/wav" src="/assets/ableplayer/VID ProbSolvJoel+VAssembly-Mobile-mono.wav" />
                </audio -->
              </div>
              <div ngNonBindable id="audiot" class="p-4">
              </div>
            </mat-tab>
            <mat-tab label="Notes">
              <div class="p-4">
                <ul>
                  <li *ngFor="let note of notes">
                    {{ note.note }}:{{ note.time }} - {{ note.question }}
                  </li>
                </ul>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-lg-8 col-md-8">
        <div class="card">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">format_align_left</mat-icon>
                Skill Survey
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Role Clarification</strong></h2>
                  </div>
                  <div class="body">
                    <!-- This embed code may be restricted to use on a single domain, see documentation at: https://help.alchemer.com/help/iframe-embed #limit-permitted-domains-for-embeds -->
                    <iframe src="https://surveys.mypraxus.com/s3/Role-Clarification-Without-a-Coach" frameborder="0"
                      width="100%" height="575" style="overflow:hidden"></iframe>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>
