

import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  // Admin Modules
 /* {
    path: "/admin/dashboard",
    state: {
      agencyId: -1
    },
    title: "MENUITEMS.HOME.TEXT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Agency Admin"],
    submenu: [

    ],
  }, */
  // Teacher Modules
  {
    path: "/teacher/dashboard",
    title: "MENUITEMS.TEACHER.LIST.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  // Student Modules
  {
    path: "/student/dashboard",
    title: "MENUITEMS.STUDENT.LIST.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Learner", "Demo Learner"],
    submenu: [
      {
        path: "/student/surveys",
        title: "Surveys",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Developer"],
        submenu: [],
      },
      {
        path: "/student/precording",
        title: "Recording",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/student/activities",
        title: "Activities ",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dashboard/coach-dashboard",
        title: "Coach Dashboard",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Coach"],
        submenu: [],
      },
      {
        path: "/admin/dashboard/agency-admin-dashboard",
        title: "Admin Dashboard",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Praxus Admin", "Agency Admin"],
        submenu: [],
      },
      {
        path: "/admin/dashboard/skill-dashboard",
        title: "Skill Dashboard",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Praxus Admin", "Agency Admin", "Coach"],
        submenu: [],
      },
    ],
  },
  {
    path: "/student/agency-list",
    title: "MENUITEMS.STUDENT.LIST.AGENCY",
    moduleName: "agency",
    iconType: "material-icons-two-tone",
    icon: "business_center",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Praxus Admin", "Agency Admin"],
    submenu: [
      {
        path: "student/agency-list",
        title: "Agency List",
        moduleName: "agency",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Praxus Admin", "Agency Admin"],
        submenu: [],
      },
      {
        path: "/student/add-edit-agency",
        title: "Add Agency",
        moduleName: "agency",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["Praxus Admin"],
        submenu: [],
      } 
    ],
  },
  {
    path: "/student/resources",
    title: "Resources",
    moduleName: "resources",
    iconType: "material-icons-two-tone",
    icon: "library_books",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Praxus Admin", "Learner"],
    submenu: [],
  },  
  {
    path: "/student/tour-library",
    title: "Tour Library",
    moduleName: "tour-library",
    iconType: "material-icons-two-tone",
    icon: "map",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Developer"],
    submenu: [],
  }, 
 /* {
    path: "/student/issues",
    title: "Issues",
    moduleName: "issues",
    iconType: "material-icons-two-tone",
    icon: "report_problem",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Praxus Admin"],
    submenu: [],
  },  */

  {
    path: "/qrcode",
    title: "Interest",
    moduleName: "interest",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Developer"],
    submenu: [],
  },
  {
    path: "/student/ntnts",
    title: "Clients",
    moduleName: "clients",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Developer"],
    submenu: [],
  },
  {
    path: "/student/tasks",
    title: "Tasks",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
  },
  {
    path: "/student/recording-list",
    title: "Recordings",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Developer"],
    submenu: [],
  }, 
  {
    path: "/student/new-recording",
    title: "New Recording",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Developer"],
    submenu: [],
  },
  // Common Module
  {
    path: "",
    title: "Authentication",
    moduleName: "authentication",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/authentication/signin",
        title: "Sign In",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/signup",
        title: "Sign Up",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/forgot-password",
        title: "Forgot Password",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/locked",
        title: "Locked",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/page404",
        title: "404 - Not Found",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/page500",
        title: "500 - Server Error",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Extra Pages",
    moduleName: "extra-pages",
    iconType: "material-icons-two-tone",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/extra-pages/blank",
        title: "Blank Page",
        moduleName: "extra-pages",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Multi level Menu",
    moduleName: "multilevel",
    iconType: "material-icons-two-tone",
    icon: "slideshow",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/multilevel/first1",
        title: "First",
        moduleName: "multilevel",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/",
        title: "Second",
        moduleName: "secondlevel",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/multilevel/secondlevel/second1",
            title: "Second 1",
            moduleName: "secondlevel",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/multilevel/secondlevel/second2",
            title: "Second 2",
            moduleName: "secondlevel",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      {
        path: "/multilevel/first3",
        title: "Third",
        moduleName: "multilevel",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
];

