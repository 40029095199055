import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../service/auth.service';
import { Auth } from '@angular/fire/auth';
import { from, map, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private auth: Auth) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):  boolean | Observable<boolean> | Promise<boolean> {
  //  var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return new Promise ((resolve, reject) => {
      this.authService.isAuthenticated().then((result)=>{
        if(result != null) {

          resolve(true)
          return result;
        } else {
          resolve(false);
          this.router.navigate(['/authentication/signin']);
        }

      })
   //   resolve(true);
    }) 


  /*  return new Promise ((resolve, reject) => {
      this.auth.onAuthStateChanged(firebaseUser => {
        if (firebaseUser) {
          console.log({firebaseUser});
          resolve(true);
        } else {
          resolve(false)
        }
      })
    }) */
   //   return true



      
  //  console.log({currentUser});
    /*
        if (this.authService.currentUserValue) {
      console.log(this.authService.currentUserValue);
      const userRole = this.authService.currentUserValue.role;
      if (route.data.role && userRole.indexOf(route.data.role) === -1) {
        this.router.navigate(['/authentication/signin']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/authentication/signin']);
    return false;  
    */
   // return true
  
}

}
