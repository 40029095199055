import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc,
  where,
  getDocs,
  query,
  Query,
  orderBy
} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private firestore: Firestore) { }

  async getNotificationsByUserId(userId: string) {
    const functions = getFunctions();
  
    const getNotifications = httpsCallable(functions, 'getNotifications');
  
    return getNotifications({userId}); 
  }

  async getNotificationsDetailsByIds(userId: string, notificationIds: any) {
    const functions = getFunctions();
  
    const getNotificationsDetails = httpsCallable(functions, 'getNotificationsDetails');
  
    return getNotificationsDetails({userId, notificationIds}); 
  }

  async updateNotificationStatus(userId: string, notificationIds: any) {
    const functions = getFunctions();
  
    const updateNotificationStatus = httpsCallable(functions, 'updateNotificationStatus');
  
    return updateNotificationStatus({userId, notificationIds}); 
  }

  // Observables
  public getNotificationsByUser(userId: string): Observable<any[]> {
    let query1: Query = query(
      collection(this.firestore, `userProfiles/${userId}/notifications`) as CollectionReference<any>,
      //  where('creationTime', '<', currentTime),
        where('messageType', 'array-contains', 'inApp'),
        where('notificationStatus.inApp.status', '==', 'Not Viewed'),
        orderBy('creationTime', 'desc')
    );

    return collectionData(query1, { idField: "id" });

    const notificationsRef = collection(this.firestore, `userProfiles/${userId}/notifications`);
    return collectionData(notificationsRef, { idField: "id" }) as Observable<any[]>;
  }

}
