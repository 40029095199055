import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
} from "@angular/fire/firestore";
import { orderBy, query } from "@firebase/firestore";

import { Goal } from "../../models/goals/goals.interface";
import { getFunctions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: "root",
})
export class GoalService {
  goals$: Observable<Goal[]>;

  constructor(private firestore: Firestore) {}

  public getGoals(): Observable<Goal[]> {
    const notesRef = collection(this.firestore, "goals");
    return collectionData(notesRef, { idField: "id" }) as Observable<Goal[]>;
  }

  public getGoals2(): Observable<Goal[]> {
    return collectionData<Goal>(
      query<Goal>(
        collection(this.firestore, "goals") as CollectionReference<Goal>,
        orderBy("createdOn")
      ),
      { idField: "id" }
    );
  }

  public getTasks(): Observable<Task[]> {
    const notesRef = collection(this.firestore, "goals");
    return collectionData(notesRef, { idField: "id" }) as Observable<Task[]>;
  }

  public getGoalByGoalId(userId: string, goalId: string): Observable<any[]> {
    const bookRef = doc(this.firestore, `userProfiles/${userId}/goals/${goalId}`);
    return docData(bookRef, { idField: 'id' }) as Observable<any>;
  }

  saveGoal(goal: any) {
    const functions = getFunctions();

    const addGoal = httpsCallable(functions, 'addGoal');

    return addGoal({body: goal}); 
  }

 /* updateGoalAndTasks(goal: any) {
    const functions = getFunctions();

    const updateGoal = httpsCallable(functions, 'updateGoal');

    return updateGoal({body: goal}); 
  } */

  updateGoal(goal: any, userId: string, goalId: string) {
    const functions = getFunctions();

    const updateGoal = httpsCallable(functions, 'updateGoal');

    return updateGoal({body: goal, userId, goalId}); 
  }

  saveTask(task: any, userId: string, goalId: string) {
    const functions = getFunctions();

    const addTask = httpsCallable(functions, 'addTask');

    return addTask({body: task, userId, goalId}); 
  }

  editTask(task: any, userId: string, goalId: string) {
    const functions = getFunctions();

    const updateTask = httpsCallable(functions, 'updateTask');

    return updateTask({body: task, userId, goalId}); 
  }

  deleteTask(task: any, userId: string, goalId: string) {
    const functions = getFunctions();

    const deleteTask = httpsCallable(functions, 'deleteTask');

    return deleteTask({body: task, userId, goalId}); 
  }

  deleteGoal(goal: any, userId: string, goalId: string) {
    const functions = getFunctions();

    const deleteGoal = httpsCallable(functions, 'deleteGoal');

    return deleteGoal({body: goal, userId, goalId}); 
  }

  updateTaskAction(task: any, goal: any, userId: string, goalId: string) {
    const functions = getFunctions();

    const updateTaskAction = httpsCallable(functions, 'updateTaskAction');

    return updateTaskAction({task: task, goal: goal, userId, goalId}); 
  }


}
