<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">Dashboard</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/student/dashboard">
                <i class="fas fa-home"></i> Home</a>
            </li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header">
            <h2>Role Clarification - Survey Results</h2>
          </div>
          <div class="tableBody">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Recording ID</th>
                    <th>Date Submitted</th>
                    <th>Scores</th>
                    <th>Observations</th>
                    <th>Praise</th>
                    <th>NUBM</th>
                    <th>IUBM</th>
                    <th>AUBM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let survey of surveys$ | async; index as i">
                    <th scope="row" (click)="selectedSurvey = survey"><button mat-stroked-button
                        color="primary">{{ survey.raw?.url_variables.recordingid.value }}</button></th>
                    <td>
                      {{ survey.raw?.date_submitted }}
                    </td>
                    <td>P&nbsp;Score:&nbsp;{{ survey.processedResults?.pscore | number }}<br />
                      O&nbsp;Score:&nbsp;{{ survey.processedResults?.oscore | number }}</td>
                    <td>{{ survey.observations }} </td>
                    <td>{{ survey.praise }} </td>
                    <td>{{ survey.nubm }} </td>
                    <td>{{ survey.iubm }} </td>
                    <td>{{ survey.aubm }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <app-recording-report [activityId]="selectedSurvey?.id"></app-recording-report>
      </div>
    </div>
  </div>
</section>
