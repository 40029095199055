<section class="content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <ul class="breadcrumb breadcrumb-style ">
                        <li class="breadcrumb-item">
                            <h4 class="page-title"></h4>
                        </li>
                        <li class="breadcrumb-item bcrumb-1">
                            <a routerLink="/student/dashboard">
                                <i class="fas fa-home"></i> Home</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row clearfix" style="max-height: 300px; margin-top: 0px; padding-top: 0px;">

            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style=" margin-top: 0px; padding-top: 0px;">
                <div class="card" style=" margin-top: 0px; padding-top: 0px;">
                    <img width="500px" src="assets/images/icja-qr.png">
                </div>
            </div>
        </div>
    </div>
</section>