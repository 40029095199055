import { Injectable } from '@angular/core';
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc,
  where,
  getDocs,
  query,
  Query
} from "@angular/fire/firestore";
// import { AngularFirestore, CollectionReference, Query, collection, where, query, collectionData } from '@angular/fire/firestore';

// import { orderBy, query } from "@firebase/firestore";
import { Observable } from 'rxjs';

import { Agency } from "../../models/agencies/agencies.interface";
import { getFunctions, httpsCallable } from '@angular/fire/functions';


@Injectable({
  providedIn: 'root'
})

export class IssuesService {

  constructor(private firestore: Firestore) { }

  public async addIssue(issue: any, userProfile: any, currentPage: string, skillId?: string, activityId?: string) {
    const functions = getFunctions();

    const addIssue = httpsCallable(functions, 'addIssue');
  
    return addIssue({issue, userProfile, currentPage, skillId, activityId}); 
  }

  public getIssuesByAgency(agencyId: string): Observable<any[]>{
    let query1: Query = query(
      collection(this.firestore, "issues") as CollectionReference<any>,
      where('agencyId', '==', agencyId),

    );
    return collectionData(query1, { idField: "id" });
  }

  public async getIssues(lastVisibleDoc: any) {
    const functions = getFunctions();

    const addIssue = httpsCallable(functions, 'getIssues');
  
    return addIssue({lastVisibleDoc}); 
  }

}
