import { Injectable } from '@angular/core';
import {
  Firestore,
  collectionData,
  collection,
  CollectionReference,
  doc,
  docData,
  setDoc,
  addDoc,
  where,
  getDocs,
  query,
  Query
} from "@angular/fire/firestore";
// import { AngularFirestore, CollectionReference, Query, collection, where, query, collectionData } from '@angular/fire/firestore';

// import { orderBy, query } from "@firebase/firestore";
import { Observable } from 'rxjs';

import { Agency } from "../../models/agencies/agencies.interface";
import { getFunctions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: any = {

  }

  constructor(private firestore: Firestore) { }

  public async getSettings(settingsName: string, onlyAgencySettings = false) {
    var settingsNameKey = settingsName + ":" + onlyAgencySettings;
    if(this.settings[settingsNameKey]) {
      return this.settings[settingsNameKey];
    } else {

      const functions = getFunctions();

      const getSettings = httpsCallable(functions, 'getSettingsFromDB');
    
      var callSettings = await getSettings({settingsName, onlyAgencySettings}); 

      this.settings[settingsNameKey] = callSettings.data;

      return this.settings[settingsNameKey];
    }

  }


}
