import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { Role } from 'src/app/core/models/role';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatDialog } from "@angular/material/dialog";
import * as _ from 'lodash';
import { IssuesModalComponent } from 'src/app/student/modal/issues-modal/issues-modal.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userIsCoach: boolean = false;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private dialogModel: MatDialog
  ) {
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ['admin', 'teacher', 'student'];
        const currenturl = event.url.split('?')[0];
        const firstString = currenturl.split('/').slice(1)[0];
        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = currenturl.split('/')[2];
          this.level2Menu = currenturl.split('/')[3];
        } else {
          this.level1Menu = currenturl.split('/')[1];
          this.level2Menu = currenturl.split('/')[2];
        }
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }
  ngOnInit() {
    if (this.authService.currentUserValue) {
      var userRoles = this.authService.currentUserValue.role;
      this.userFullName = this.authService.currentUserValue.firstName + ' ' + this.authService.currentUserValue.lastName;
      this.userImg = this.authService.currentUserValue.img;
      
      if (userRoles.includes('Coach')) { this.userIsCoach = true; }

   //   this.sidebarItems = ROUTES.filter(route => route.role.some(role => userRoles.includes(role)));
      this.sidebarItems = this.filterRoutesByRole(ROUTES, userRoles);

 /*     if (userRole === Role.Admin) {
        this.userType = Role.Admin;
      } else if (userRole === Role.Teacher) {
        this.userType = Role.Teacher;
      } else if (userRole === Role.Student) {
        this.userType = Role.Student;
      } else {
        this.userType = Role.Admin;
      } */
    }
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }
  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

   filterRoutesByRole(routes: any[], rolesToMatch: string[]): any[] {
    return routes.reduce((filteredRoutes, route) => {
      if (route.role.some(role => rolesToMatch.includes(role))) {
        const filteredSubmenu = route.submenu ? this.filterRoutesByRole(route.submenu, rolesToMatch) : undefined;
        filteredRoutes.push({...route, submenu: filteredSubmenu});
      } else if (route.submenu) {
        const filteredSubmenu = this.filterRoutesByRole(route.submenu, rolesToMatch);
        if (filteredSubmenu.length > 0) {
          filteredRoutes.push({...route, submenu: filteredSubmenu});
        }
      }
      return filteredRoutes;
    }, [] as any[]);
  }


  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  reportAnIssue() {
    const dialogRef: any = this.dialogModel.open(IssuesModalComponent, {
      width: '800px',
      disableClose: true,
    });
    dialogRef.componentInstance.data = "some data"; 
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }
}
