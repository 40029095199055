import { Transcript } from './../../core/models/transcripts/transcripts.interface';
import { Recording } from './../../core/models/recordings/recordings.interface';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, combineLatest, firstValueFrom, map } from 'rxjs';
import { Survey, SurveyObservation, SurveyPraise } from 'src/app/core/models/surveys/surveys.interface';
import { SurveyService } from './../../core/service/surveys/surveys.service';
import { RecordingService } from "./../../core/service/recordings/recordings.service";
import { SurveyResult } from 'src/app/core/models/surveys/surveys.interface';
import { Role } from 'src/app/core/models/role';
import { AuthService } from 'src/app/core/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';


import * as _ from "lodash";

@Component({
  selector: 'app-recording-report',
  templateUrl: './recording-report.component.html',
  styleUrls: ['./recording-report.component.sass']
})
export class RecordingReportComponent implements OnInit {

  @Input()
  activityId: any;

  @Input()
  skillId: any;

  @Input()
  surveyId: any;

  @Input()
  recordingId: any;

  @Input()
  activityOwnerId: any;

  Role: typeof Role = Role; 

  public myReport = true;

  public sliceValue = 1;

  //public recordingId?: string;
  public recording$: Observable<Recording>;
  public survey$: Observable<Survey>;
  public transcript$: Observable<Transcript>
  public currentUser: any;
  public userTypeAndLevel: { userType: string, level: string} = { userType: "", level: "" };

  public surData: any;

  public reportCheckboxes = [
  ]

  public reportNotes?: {
    step0?: {
      coachesNote?: string,
      learnersNote?: string
    },
    step1?: {
      coachesNote?: string,
      learnersNote?: string
    },
    step2?: {
      coachesNote?: string,
      learnersNote?: string
    },
    step3?: {
      coachesNote?: string,
      learnersNote?: string
    },
    step4?: {
      coachesNote?: string,
      learnersNote?: string
    },
    step5?: {
      coachesNote?: string,
      learnersNote?: string
    }
  } = {
    step0: {
      coachesNote: '',
      learnersNote: ''
    },
    step1: {
      coachesNote: '',
      learnersNote: ''
    },
    step2: {
      coachesNote: '',
      learnersNote: ''
    },
    step3: {
      coachesNote: '',
      learnersNote: ''
    },
    step4: {
      coachesNote: '',
      learnersNote: ''
    },
    step5: {
      coachesNote: '',
      learnersNote: ''
    }
  };

  hasSelectedCheckbox(type: string, code: string): boolean {
    return this.reportCheckboxes.includes(type + ":" + code);
  }

  toggleCheckbox(type: string, code: string, event: any): void {
    
    if (event.target.checked) {
      this.reportCheckboxes.push(type + ":" + code);
    } else if (this.reportCheckboxes.includes(type + ":" + code)) {
      this.reportCheckboxes = this.reportCheckboxes.filter((item) => item !== type + ":" + code);
    }
  }


  public data$: Observable<{
    recording: Recording, survey: Survey, transcript: Transcript, questions: any,
    step0p: SurveyPraise[],
    step0o: SurveyObservation[],
    step1p: SurveyPraise[],
    step1o: SurveyObservation[],
    step2p: SurveyPraise[],
    step2o: SurveyObservation[],
    step3p: SurveyPraise[],
    step3o: SurveyObservation[],
    step4p: SurveyPraise[],
    step4o: SurveyObservation[],
    step5p: SurveyPraise[],
    step5o: SurveyObservation[],
  }>
  
  constructor(
    private RecordingService: RecordingService,
    private SurveyService: SurveyService,
    public authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  public skillStepTable = [
    { skillName: 'Role Clarification', skillInitials: 'rc', steps: [0, 1, 2, 3, 4, 5], maxNubm: 23, maxIubm: 19, maxAubm: 15 },
    // Add more skills and steps as needed
  ];

  getMaxValue(skillName: string, level: string ): number {
    let skill = this.skillStepTable.find((item: any) => item.skillName === skillName);
    if (!skill) {
      skill = this.skillStepTable.find((item: any) => item.skillInitials === skillName);
    }

    if (skill) {
      if (level == "nubm") {
        return skill.maxNubm;
      } else if (level == "iubm") {
        return skill.maxIubm;
      } else if (level == "aubm") {
        return skill.maxAubm;
      }
    }
    return 0;
  }

  hasStep(skillName: string, step: number): boolean {
    // Assuming you have a datatable called "datatable" with the necessary data

    // Find the skill in the datatable
    const skill = this.skillStepTable.find((item: any) => item.skillName === skillName);

    // If the skill is found and the step exists, return true
    if (skill && skill.steps.includes(step)) {
      return true;
    }

    // Otherwise, return false
    return false;
  }

  ngOnInit(): void {

    console.log("********" + this.surveyId);
    const dataValues = [
      "NUBM:1.1", "IUBM:2.1", "IUBM:2.2", "AUBM:3.1", 
      // ... (include all other data values here)
      "AUBM:3.10", "AUBM:3.11"
  ];


 if (this.activityOwnerId != this.authService.currentUserValue.id) {
    this.sliceValue = 20;
    this.myReport = false;
  }

  /*
  const table = document.querySelector('table-wes');

  dataValues.forEach(value => {
      const tr = document.createElement('tr');

      const tdValue = document.createElement('td');
      tdValue.textContent = value;
      tr.appendChild(tdValue);

      for (let i = 0; i < 3; i++) {
          const td = document.createElement('td');
          if (Math.random() < 0.5) {
              td.textContent = '✓';
              td.classList.add('green');
          }
          tr.appendChild(td);
      }

      table.appendChild(tr);
  });

  */
  }

returnTrueXPercentOfTheTime(x: number): boolean {
  const random = Math.random() * 100;
  return random <= x;
}


  /**
   * 
   * create a function that takes in three arrays, new user "nubm". intermediate user "iubm", and advanced user "aubm"
   * also passed in will be the max values of the count of nubm, iubm, and aubm
   * if 80% of the nubm is achvied the user is new user, 
   * if 80% of the iubm is achvied the user is intermediate user,
   * if 80% of the aubm is achvied the user is advanced user,
   * once the user is determined, the function will return the user type
   * also the function will return low, medium, or high based on the percentage of the next level
   */
  determineUserType(nubm: any[], iubm: any[], aubm: any[], maxNubm: number, maxIubm: number, maxAubm: number): { userType: string, level: string } {
    const nubmAchieved = nubm.length / maxNubm;
    const iubmAchieved = iubm.length / maxIubm;
    const aubmAchieved = aubm.length / maxAubm;
 
console.log(nubmAchieved, iubmAchieved, aubmAchieved, nubm.length, maxNubm, iubm.length, maxIubm, aubm.length, maxAubm);

    if (aubmAchieved >= 0.8) {
      return { userType: "advanced user", level: "high" };
    } else if (iubmAchieved >= 0.8) {
      return { userType: "intermediate user", level: this.getLevel(aubmAchieved) };
    } else if (nubmAchieved >= 0.8) {
      return { userType: "new user", level: this.getLevel(iubmAchieved) };
    }

    return { userType: "new user", level: this.getLevel(nubmAchieved) };
  }

  private getLevel(percentage: number): string {
    if (percentage < 0.4) {
      return "low";
    } else if (percentage < 0.7) {
      return "medium";
    } else {
      return "high";
    }
  }



  async ngOnChanges(changes: SimpleChanges) {

    this.currentUser = await firstValueFrom(this.authService.currentUser);
    console.log(this.currentUser);

    console.log("******** surveyId: " + this.surveyId + ", recordingId: " + this.recordingId);

    this.recording$ = this.RecordingService.getRecordingById(this.recordingId);
    this.survey$ = this.SurveyService.getSurveyById(this.surveyId);
    this.transcript$ = this.RecordingService.getTranscriptById(this.recordingId);


    this.data$ = combineLatest([this.recording$, this.survey$, this.transcript$]).pipe(
      map(([rec, sur, trans]) => {
        const nubm = sur.nubm;
        const iubm = sur.iubm;
        const aubm = sur.aubm;
        const maxNubm = this.getMaxValue(sur.skill, "nubm");
        const maxIubm = this.getMaxValue(sur.skill, "iubm");
        const maxAubm = this.getMaxValue(sur.skill, "aubm");

        this.reportCheckboxes = sur.selectedFeedback || [];

        if (sur.raw?.url_variables?.type?.value == "woc") {
          let selectedFeedback:string[] = [];
          sur.processedResults.praise.forEach((element:SurveyPraise) => {
              selectedFeedback.push('p:' + element.Code);
          });
          sur.processedResults.observations.forEach((element:SurveyObservation) => {
              selectedFeedback.push('o:' + element.Code);
          });
          this.reportCheckboxes = selectedFeedback;
      }

        if (sur.notes)
          this.reportNotes = sur.notes;

        this.userTypeAndLevel = this.determineUserType(nubm, iubm, aubm, maxNubm, maxIubm, maxAubm);

        return this.surData = {
          recording: rec,
          survey: sur,
          transcript: trans,
          questions: Object.values(sur.raw.survey_data),
          step0p: sur.processedResults.praise.filter(p => {
            return p.Step == 0 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("p", p.Code) ));
          }),
          step0o: sur.processedResults.observations.filter(p => {
            return p.Step == 0 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("o", p.Code) ));
          }),
          step1p: sur.processedResults.praise.filter(p => {
            return p.Step == 1 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("p", p.Code) ));
          }),
          step1o: sur.processedResults.observations.filter(p => {
            return p.Step == 1 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("o", p.Code) ));
          }),
          step2p: sur.processedResults.praise.filter(p => {
            return p.Step == 2 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("p", p.Code) ));
          }),
          step2o: sur.processedResults.observations.filter(p => {
            return p.Step == 2 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("o", p.Code) ));
          }),
          step3p: sur.processedResults.praise.filter(p => {
            return p.Step == 3 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("p", p.Code) ));
          }),
          step3o: sur.processedResults.observations.filter(p => {
            return p.Step == 3 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("o", p.Code) ));
          }),
          step4p: sur.processedResults.praise.filter(p => {
            return p.Step == 4 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("p", p.Code) ));
          }),
          step4o: sur.processedResults.observations.filter(p => {
            return p.Step == 4 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("o", p.Code) ));
          }),
          step5p: sur.processedResults.praise.filter(p => {
            return p.Step == 5 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("p", p.Code) ));
          }),
          step5o: sur.processedResults.observations.filter(p => {
            return p.Step == 5 && (this.authService.checkUserRoles([Role.Coach, Role.PraxusAdmin])|| (this.myReport && this.hasSelectedCheckbox("o", p.Code) ));
          }),
        }
      })
    );
  }

  hasComment(fb: any): boolean {
    if (this.surData.survey.raw.survey_data[fb.Question].comments != undefined) {
      return true;
    }
    return false;
  }

  getComment(fb: any): string {
    if (this.surData.survey.raw.survey_data[fb.Question].comments != undefined) {
      return this.surData.survey.raw.survey_data[fb.Question].comments;
    }
    return "";
  }


  getRand(ob: any): Boolean {

    return (Math.random() > ob)
  }

  processAnswer(value: any): string {
    if (value.answer != undefined) {
      return value.answer;
    }

    let result = ""
    if (value.options != undefined) {
      _.forOwn(value.options, (v2, k2) => {
        if (v2.option != undefined) {
            result += v2?.option + "</br>";
        }
    })
    }

    return result;
  }

  levelClass(ob: any): string {

    if (ob?.Code?.startsWith("l1")) {
      return "level1";
    } else if (ob?.Code?.startsWith("l2")) {
      return "level2";
    } else if (ob?.Code?.startsWith("l3")) {
      return "level3";
    } 

    return "";
  }


  levelText(ob: any): string {

    if (ob?.Code?.startsWith("l1")) {
      return "New User";
    } else if (ob?.Code?.startsWith("l2")) {
      return "Intermediate User";
    } else if (ob?.Code?.startsWith("l3")) {
      return "Advanced User";
    } 

    return "";
  }


  getBackgroundColor(number: number): string {
    if (number % 2 === 0) {
      return '#fff'; // Even number
    } else {
      return '#F8F8F8'; // Odd number
    }
  }

  saveReport() {
    this.SurveyService.saveSelectedFeedback(this.surveyId, this.reportCheckboxes, this.reportNotes);
    this.snackBar.open('Changes saved successfully.', '', {
      duration: 5000,
      panelClass: ['customSnackbar']
    }); 
  }

  publishReport() {
    this.SurveyService.publishReport(this.activityOwnerId, this.skillId, this.activityId);
    this.snackBar.open('Report published to Learner.', '', {
      duration: 5000,
      panelClass: ['customSnackbar']
    }); 
  }


}
