import { ConfigService } from '../../config/config.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { AuthService } from 'src/app/core/service/auth.service';
import { Router } from '@angular/router';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { Role } from 'src/app/core/models/role';
import { LanguageService } from 'src/app/core/service/language.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { NotificationsService } from 'src/app/core/service/notifications/notifications.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { IssuesModalComponent } from 'src/app/student/modal/issues-modal/issues-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

const document: any = window.document;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, AfterViewInit
{
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  notifications: any = []
  notificationOpened: boolean = false;
  notificationIds: any[] = []
  notifications$: Observable<any>;
  totalNotificationNumber: number = 0;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    public authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private notificationsService: NotificationsService,
    private dialogModel: MatDialog,
  ) {
    super();
  }
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
  ];
 /* notifications: any[] = [
    {
      userImg: 'assets/images/user/user1.jpg',
      userName: 'New Activity Added',
      time: '5 mins ago',
      message: 'Skill Demo - See Effective Use of Reinforcement in Action',
    },
    {
      userImg: 'assets/images/user/user2.jpg',
      userName: 'New Activity Added',
      time: '5 mins ago',
      message: 'Course 101 - Learning Effective Use of Reinforcement',
    },
    {
      userImg: 'assets/images/user/user3.jpg',
      userName: 'New Activity Added',
      time: '5 mins ago',
      message: 'Surfing the Three Waves',
    },
  ]; */
  ngOnInit() {
    this.config = this.configService.configData;
    const userRole = this.authService.currentUserValue.role;
    this.userImg = this.authService.currentUserValue.img;

    this.homePage = 'student/dashboard';
/*
    if (userRole === Role.Admin) {
      this.homePage = 'admin/dashboard/main';
    } else if (userRole === Role.Teacher) {
      this.homePage = 'teacher/dashboard';
    } else if (userRole === Role.Student) {
      this.homePage = 'student/dashboard';
    } else {
      this.homePage = 'admin/dashboard/main';
    } */
    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    // get notifications on page load
    this.notificationsService.getNotificationsByUserId(this.authService.currentUserValue.id).then((res:any)=>{
      if(res.data.length > 0) {
        this.notificationIds = res.data;
        this.notificationsService.getNotificationsDetailsByIds(this.authService.currentUserValue.id, this.notificationIds).then((results:any)=>{
        //  this.notifications = results.data;
            this.notifications.push(...results.data);
            this.notifications = _.uniqBy(this.notifications, 'id');
            this.notifications = _.orderBy(this.notifications, ['creationTime'], ['asc']);
            
            
          // Count where status is Not Viewed. Then, set the count to totalNotificationNumber
         // this.totalNotificationNumber = this.notifications.length;
        })
      } else {
        this.notifications = [];

      }
    }) 

    const maxNumber = 15;

    this.notifications$ = this.notificationsService.getNotificationsByUser(this.authService.currentUserValue.id);
    this.notifications$.subscribe((userNotifications: any)=>{
      // get the ids of notifications, then get the details.
       var notificationIds = [];

        userNotifications.forEach((eachNotification:any)=>{
          if(eachNotification.id) {
            // Check eachNotification.id exists in this.notifications, if the id already exists in the array, don't push the id.
            // Otherwise, push the ids.
            // Max 15 items in notificationIds array
            const notificationObject = this.notifications.find((notification: any) => notification.id === eachNotification.id);

            if (!notificationObject) {
              notificationIds.push(eachNotification.id);
            }
          }
        })
  
        // After getting the ids, get the notification details.
        if(notificationIds.length > 0) {
          notificationIds = _.uniq(notificationIds);

          this.notificationsService.getNotificationsDetailsByIds(this.authService.currentUserValue.id, notificationIds).then((subscriptionResults:any)=>{
            // Add ...subscriptionResults.data to this.notifications
              this.notifications.unshift(...subscriptionResults.data);
              this.notifications = _.uniqBy(this.notifications, 'id');
              this.notifications = _.orderBy(this.notifications, ['creationTime'], ['asc']);
              
            // If this.notifications.length > 15, remove any documents over 15 where the status is "Viewed".

            if(this.notifications.length > maxNumber && this.notifications[this.notifications.length - 1].status == "Viewed") {
              for(var i = this.notifications.length - 1; (i >= maxNumber && i > -1 && this.notifications[i].status == "Viewed"); i--) {
                if(this.notifications[i].status == "Viewed") {
                  this.notifications.length = i;
                }
               
              }
            } 

            // Count where status is Not Viewed. Then, increment the count to totalNotificationNumber
            this.totalNotificationNumber = _.countBy(this.notifications, i => i.status !== "Viewed").true;

          })
        } 
    })

  }
  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }
    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }
    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }
    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }
  markNotificationsRead() {
    // Get the notification ids from this.notifications where status is "Not Viewed".
    var notificationIds = [];
    this.notifications.forEach((eachNotification:any)=>{
      if(eachNotification.status != "Viewed") {
        notificationIds.push(eachNotification.id);
      }
    })
    if(notificationIds.length > 0) {
      this.notificationsService.updateNotificationStatus(this.authService.currentUserValue.id, notificationIds).then((result:any)=>{
        if(result.data.success == true) {
          this.totalNotificationNumber = 0;
          // For each notificationIds in this.notifications, set status to "Viewed" locally.
          this.notifications.forEach((eachNotification:any)=>{
            eachNotification.status = "Viewed";
          })
        }
      })
      
    }
  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
  public toggleRightSidebar(): void {
    this.subs.sink = this.rightSidebarService.sidebarState.subscribe(
      (isRunning) => {
        this.isOpenSidebar = isRunning;
      }
    );
    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  reportAnIssue() {
    const dialogRef: any = this.dialogModel.open(IssuesModalComponent, {
      width: '800px',
      disableClose: true,
    });
    dialogRef.componentInstance.data = "some data"; 
  }

  logout() {
    this.subs.sink = this.authService.logout().subscribe((res) => {
      //if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      //}
    });
  }
}
