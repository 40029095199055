
<style>
   @media print {

    app-header {
      display: none;
    }

  }
</style>

<app-header></app-header>
<app-sidebar></app-sidebar>
<app-right-sidebar></app-right-sidebar>
<router-outlet></router-outlet>
