import * as _ from 'lodash';
import { SurveyService } from '../../service/surveys/surveys.service';
 export interface SurveyObservation {
    Question: string;
    Feedback: string;
    Notes: string;
    Points: number;
    Scale: string;
    Step: number;
    Code: string;
  }
  
  export interface SurveyPraise {
    Question: string;
    Strength: string;
    Notes: string;
    Points: number;
    Scale: string;
    Step: number;
    Code: string;
  }
  
  export interface SurveyBenchmark {
    Question: string;
    Benchmark: string;
    Step: number;
    SkillLevel: string;
    Description: string;
    Points: number;
  }
  
  export interface SurveyResult {
    observations: SurveyObservation[];
    praise: SurveyPraise[];
    nubm: SurveyBenchmark[];
    iubm: SurveyBenchmark[];
    aubm: SurveyBenchmark[];
    unknown: string[];
    pscore: number;
    oscore: number;
    nscore: number;
    iscore: number;
    ascore: number;
  }
 
  export interface Survey {
    surveyId?;
    id: string;
    aubm: string[];
    iubm: string[];
    nubm: string[];
    observations: string[];
    praise: string[];
    processedResults: SurveyResult;
    raw: any;
    skill?: string;
    selectedFeedback?: string[];
    notes?: { 
      step0?: {
      coachesNote?: string,
      learnersNote?: string
      },
      step1?: {
      coachesNote?: string,
      learnersNote?: string
      },
      step2?: {
      coachesNote?: string,
      learnersNote?: string
      },
      step3?: {
      coachesNote?: string,
      learnersNote?: string
      },
      step4?: {
      coachesNote?: string,
      learnersNote?: string
      },
      step5?: {
      coachesNote?: string,
      learnersNote?: string
      }
    }
  }
  
  export interface RawSurvey {
    date_submitted: string;
  }

  export class SurveyResponseProcessor {
    // variables
    survey;
    surveyResponse;
    constructor(surveyResponse: any, survey: any) {
      this.survey = survey;
      this.surveyResponse = surveyResponse;
    }

    // methods 

    getId(value) {
      if(typeof value == 'number') {
        return value;
      } else {
        return this.survey.shortnames[value]
      }
    }

    hasAnswer(value) {
      // value is a question id or shortname
      var id = this.getId(value);

      if(id) {
        return true;
      } else {
        return false;
      }
    }
  
    public getSurveyAnswer(questionId: any, surveyData?: any) {
      if(typeof questionId == 'number' && this.surveyResponse) {
        // question
          var questionObj = this.surveyResponse[questionId];

          if(questionObj) {
           return questionObj.answer;
          } else {
           return "question not found."
          }
      } else if(typeof questionId == 'string') {
        // shortname 

          var questionObj = this.surveyResponse[this.survey.shortnames[questionId]]
          if(questionObj) {
            return questionObj.answer;
           } else {
            return "question not found."
           }
      }
    /*  if(questionId && surveyData) {
         var questionObj = surveyData[questionId];
         if(questionObj) {
          return questionObj.answer;
         } else {
          return "question not found."
         }
      } else {
        return "missing input parameters."
      } */
    }
  
    // Simple textbox answers etc.
    public getSurveyQuestionAndAnswer(questionId: any, surveyData: any) {
  
      if(questionId && surveyData) {
         var questionObj = surveyData[questionId];
         if(questionObj.question && questionObj.answer) {
          return {
            question: questionObj.question, 
            answer: questionObj.answer
          };
         } else {
          return "question not found."
         }
      } else {
        return "missing input parameters."
      }
    }
  
    public getQuestionById(questionId: any) {

      if(typeof questionId == 'number' && this.survey) {
        // question
          var questionObj = this.survey.questions[questionId];

          if(questionObj) {
           return questionObj.title;
          } else {
           return "question not found."
          }
      } else if(typeof questionId == 'string') {
        // shortname 

          var questionObj = this.survey.questions[this.survey.shortnames[questionId]]
          if(questionObj) {
            return questionObj.title;
           } else {
            return "question not found."
           }
      }

      if(questionId && this.survey) {
        var questionObj = this.survey.questions[questionId];
        if(questionObj.title) {
          return questionObj.title
        } else {
         return "question not found."
        }
     } else {
       return "missing input parameters."
     }
    }
  
  
    // Get the ranks the user ordered the options. This will return an array of objects with the id, option and rank (even if rank is a blank string).
     public getRankAnswer(id: any): any {
      const survey = this.surveyResponse[id];
  
      if (!survey) {
          console.log(`Survey with ID ${id} not found`);
      }
  
      if (_.isString(survey.answer)) {
          return [{ id: parseInt(id), option: survey.answer, rank: '' }];
      } else {
          const answerObject = survey.answer;
          return Object.values(answerObject).map((answer: any) => ({
              id: answer.id,
              option: answer.option,
              rank: answer.rank
          }));
      }
    }
  
    // returns checkbox and options answers
    public getOptionsAnswers(id: any): any {
      var question;
      if(typeof id == 'number') {
        question = this.surveyResponse[id];
      } else if (typeof id == 'string') {
        question = this.surveyResponse[this.survey.shortnames[id]]
      }
  
      const checkboxAnswers: any[] = [];
  
      for (const optionId in question.options) {
          if (question.options.hasOwnProperty(optionId)) {
              const option = question.options[optionId];
              checkboxAnswers.push({
                  id: option.id,
                  option: option.option,
                  answer: option.answer,
                  original_answer: option.original_answer ? option.original_answer : undefined
              });
          }
      }
  
      return checkboxAnswers;
  }
  
  // Returns an array of radio answers from a radio grid that share the same parent. 
  public getRadioButtonGrid(parentId: number):any {
    const radioButtonGrid: any = [];
  
    for (const key in this.surveyResponse) {
        if (this.surveyResponse.hasOwnProperty(key)) {
            const question = this.surveyResponse[key];
            if (question.parent === parentId && question.type === "RADIO") {
                radioButtonGrid.push(question);
            }
        }
    }
  
    return radioButtonGrid;
  }

  }
