import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { SurveyService } from "../core/service/surveys/surveys.service";
import { Survey } from '../core/models/surveys/surveys.interface';

@Component({
  selector: "app-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.scss"],
})

export class SurveyListComponent implements OnInit {
  selectedSurvey?: Survey;
  surveys$: Observable<Survey[]>;

  constructor(surveyService: SurveyService) {
    this.surveys$ = surveyService.getSurveys();
  }

  ngOnInit(): void {}
}
