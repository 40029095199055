import { ActivityService } from './../core/service/activities/activities.service';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { Observable } from 'rxjs';
import { Activity } from '../core/models/activities/activities.interface';
@Component({
  selector: "app-p-recording",
  templateUrl: "./p-recording.component.html",
  styleUrls: ["./p-recording.component.sass"],
})
export class PRecordingComponent implements OnInit {
  @ViewChild("audio1") player: ElementRef<HTMLAudioElement>;

  notes: any[] = [];
  public audioNoteTabGroupIndex = 0;
  activity$: Observable<Activity>;

  constructor(private elementRef:ElementRef, private activitesService: ActivityService) 
  {
    this.activity$ = activitesService.getActivityById("cFWRLJaBgOA6tmqpWYuB");
  };

ngAfterViewInit() {
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.src = "assets/ableplayer/build/ableplayer.js";
  this.elementRef.nativeElement.appendChild(s);
}

  @HostListener("window:message", ["$event"])
  onMessage(btn) {

    if (btn.data.startsWith("sgE")) {
      this.notes.push(
        {
          "note": "Question/Audio Time",
          "time": this.player.nativeElement.currentTime,
          "question": btn.data
        }
      )
    }

    console.log({audio: this.player.nativeElement.currentTime, sg: btn.data});
  }
  @HostListener("document:keydown", ["$event"])
  onKeydown(KeyboardEvent) {
    if (KeyboardEvent.keyCode == 191 && KeyboardEvent.shiftKey) {
    //console.log(KeyboardEvent);
    }

    if (KeyboardEvent.key == 'n') {
      this.audioNoteTabGroupIndex = 1;
    }


    if (KeyboardEvent.key == 'a') {
      this.audioNoteTabGroupIndex = 0;
    }

  }
  @HostListener("window:click", ["$event.target"])
  onClick(targetElement: string) {
    //console.log(`You clicked on`, targetElement);
  }
  ngOnInit(): void {}
}
